
  
  .logo {
    display: block;
    width: 70px;
    height: 71px;
    background: url(../assets/logo-70.png) no-repeat;
  }
   

  .welcome {
    position: absolute;
    top: 5.2em;
    right: 9.7em;
  }
  
  .welcome .link {
    position: relative;
  }
  
  .logout {
    top: 8em;
    right: 10.8em;
  }
  .busqueda {
    width: 200px;
  }

  @media (min-width: 768px) {
.form-inline .form-control { 
    width: 400px;    
}}

.navbar {
  z-index: 10000;
  position: fixed;
  top: 0px;
  width: 100%;
}

body {
  padding-top: 90px;
}