
  .element {
    position: relative;
    top: 80px;
    
  }

.element .menu {
    position: fixed;
    top: 87px;
    background-color: white;
    z-index: 1000;
    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: 3px solid #793e83;
    border-top: 3px solid #793e83;
  }
  