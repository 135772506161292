/*! `Custom` Bootstrap 4 theme */
@import url(https://fonts.googleapis.com/css?family=Muli:200,300,400,700);

$headings-font-family:Muli;

$theme-colors: (
  "primary": #793e83,
  "secondary":#C9D6DF,
  "success":#11D3BC,
  "danger":#F67280,
  "info":#A2D5F2,
  "warning":#FF7E67,
  "light":#FAFAFA,
  "dark":#4e4e4e
);


@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
$input-bg:$gray-200;

// Add SASS theme customizations here..

// Import Bootstrap and its default variables
@import '../node_modules//bootstrap/scss/bootstrap.scss'
