.login-container {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 300px;
  height: 100px;
  border-radius: 3px;
}

.logo-login {
  position: inherit;
  left: -5em;
  bottom: 100px;
  width: 500px;
  height: 400px;
  background: url(./assets/logo-500.png) no-repeat;
}
/* 
.login-container .error {
  margin-left: 10em;
  
} */
/* 
form {
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
} */

.login-form .btn.success {
  right: 0;
  margin-top: .3em;
}

.login-container .forgot-password {
  font-size: 0.8em;
  /*margin-top: 4em;*/
  color: #999999;
}